/* FILE DOCUMENT START */
.file-document-header-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.file-document-head {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: #543310;
}
.file-document-table-heading {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: #ffffff !important;
  background: #74512d !important;
}
.file-document-table-data {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  background: #f8f4e1 !important;
  color: #000000;
}
.file-document-table-block {
  background: #f8f4e1;
  height: calc(100vh - 28vh);
  padding: 20px;
  border-radius: 20px;
  overflow: auto;
  scrollbar-width: none;
}
.--first-heading {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.--last-heading {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.file-name-title {
  color: #624d30;
  font-size: 18px;
  font-family: Inter;

  font-weight: 700;
  line-height: normal;
  position: absolute;
  right: 10px;
  top: 5px;
}
.file-upload-name-title {
  color: #624d30;
  font-size: 14px;
  font-family: Inter;
  padding: 3px 0px;
  font-weight: 700;
  line-height: normal;
}
.file-share-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.file-share-icon:hover {
  background-color: #ffffff;
}
.pkl-download-heading {
  font-family: Calisto MT;
  font-size: 28px;
  font-weight: bold;
  font-style: italic;
}
.pkl-download-from-to-address-head {
  font-family: Cambria;
  font-size: 20px;
  font-weight: bold;
}
.pkl-download-address {
  font-family: Cambria;
  font-size: 13px;
  border-bottom: 1.5px solid black;
  margin: 2px 0px;
  line-height: normal;
  font-weight: 500;
}
.pkl-download-from-address {
  font-family: Cambria;
  font-size: 16.5px;
  font-style: italic;
  margin: 2px 0px;
  line-height: normal;
  font-weight: 500;
}

.pkl-download-table-head {
  border: 1px solid black;
  padding: 1px;
  font-family: Calibri;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.pkl-download-table-data {
  border: 1px solid black;
  padding: 1px;
  font-family: Calibri;
  font-size: 12.5px;
  font-weight: 400;
  text-align: center;
}
.swan-download-address {
  font-family: Calibri;
  font-size: 16px;
  margin: 2px 0px;
  font-weight: 600;
}
.swan-download-heading {
  font-family: Calibri;
  font-size: 18px;
  font-weight: bold;
}
/* FILE DOCUMENT END */

.agent-management-table {
  width: 90% !important;
  /* border: 1px solid red !important; */
  /* overflow: auto; */
}
