@import url(./App.css);
@import url(./Timeline.css);
@import url(./Model.css);
@import url(./newupdate.css);
:root {
  --buttonfrst: #af8f6f80;
  --buttonsec: #74512d;
  --buttonThree: #af8f6f;
  --buttonactive: #543310;
  --themeColor: #f8f4e1;
  --white: #ffff;
  --black: #000;
  --countColor: #624d30;
  --line-color: #91b90b;
  --medium: 24px;
  --small: 22px;
  --xsmall: 14px;
  --btnmediam: 16px;
  --btnfont: 18px;
  --xx-small: 12px;
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --footerbgcolor: #3a3128;
  --footerbglabel: #5a6835;
}
#root {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
body {
  font-family: "Inter", sans-serif !important;
}
.sidebar_head {
  width: 265px;
  height: 100vh;
  filter: drop-shadow(4px 0px 10px var(--buttonsec));
  background-color: var(--themeColor);
  background: var(--themeColor);
}
.sidebar_logo {
  background-color: var(--black);
}
.nav-label {
  font-size: var(--btnfont);
  font-weight: 600 !important;
  line-height: 21.78px;
  text-align: left;
  color: var(--buttonactive);
  padding-left: 0px !important;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  /* margin-top: 20px; */
}
.nav-items {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;
  /* margin:var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important; */
  /* margin-left: 0px !important; */
  /* padding-left: 0px !important; */
}
.nav_active {
  margin: 0px !important;
  background: var(--buttonsec);
}
.nav_active .nav-label {
  color: var(--white);
}
.headerTimeLine {
  background-color: var(--themeColor);
  padding: 0.5rem;
  /* margin: 0px 2rem; */
}
.headerTimeLine_left {
  background-color: var(--themeColor);
  padding: 0.5rem;
  filter: drop-shadow(4px 23px 10px var(--buttonsec));
  height: calc(100vh - 67px);
  width: 280px;
  /* height: 100%; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin: 0px 2rem; */
}
.label_sidebar_right {
  /* font-family: 'Nunito'; */
  font-style: normal;
  font-weight: 800;
  font-size: var(--btnmediam);
  line-height: 20px;
  text-align: center;
  padding: 0px;
  margin: 0px;

  color: var(--buttonactive);
}
.btn_labels {
  padding: 11px 20px;
  cursor: pointer;
  margin: 1px 0px;
  transition: transform 0.2s;
  background-color: var(--buttonThree);
  filter: drop-shadow(4px 4px 5px var(--buttonactive));
}
.btn_labels:hover {
  padding: 16px 20px;
  transform: scale(1.04);
  /* margin: 0px 20px; */
  scale: 1px;
  background-color: var(--buttonactive);
  filter: drop-shadow(4px 4px 5px var(--buttonactive));
}
.active_home {
  padding: 16px 20px;
  transform: scale(1.04);
  /* margin: 0px 20px; */
  scale: 1px;
  background-color: var(--buttonactive);
  filter: drop-shadow(4px 4px 5px var(--buttonactive));
}
.right_col {
  display: flex;
  justify-content: end;
}
.btn_labels:hover .label_sidebar_right {
  color: var(--white);
}
.active_home .label_sidebar_right {
  color: var(--white);
}
/* Model Start*/
.model_header {
  background-color: var(--buttonsec);
  height: 220px;
  padding: 10px;
  position: absolute;
  width: 100%;
  top: -4px;
  left: -2px;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 24%,
    70% 28%,
    53% 31%,
    31% 38%,
    0 50%
  );
  /* box-shadow: 7px 7px 7px rgba(84, 51, 16, 0.6); */
}
.model_btn {
  background: #ffffff;
  box-shadow: 2px 2px 4px #543310;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  margin: 0px 20px;
}
.model_main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background: var(--themeColor);
  height: 220px;
  box-shadow: 24;
}
/* Model End */

/*Eway Table Start */
.table-container {
  overflow-x: auto;
}

.ewaytable {
  border-collapse: collapse;
  width: 50%;
  overflow: scroll;
}

.eway_table_headerlabel,
.eway_table_bodylabel {
  /* border: 1px solid #ddd; */
  padding: 5px 0px 5px 5px;
  text-align: center;
}

.eway_table_header {
  color: white;
  font-size: var(--xx-small);
  border-radius: 16px;
}

/* tr:hover {
  background-color: #ddd;
} */

.eway_table_headerlabel {
  background-color: #8b4513;
}
.eway_table_headerlabel:first-child {
  border-radius: 5px 0px 0px 5px;
}
.eway_table_headerlabel:last-child {
  border-radius: 0px 5px 5px 0px;
}
.eway_table_headerlabel,
.eway_table_bodylabel {
  /* min-width: 100px; */
  /* height: 50px; */
  font-size: var(--xx-small);
}

/*Eway Table End */
/* SIde BAr */
/* @media (min-width: 100px) and (max-width: 5px) {
  .sidebar_head {
    width: 100% !important;
  }
} */
/* SIde BAr */
.btn_download_file {
  padding: 5px 20px;
  transform: scale(1.04);
  color: var(--white);
  scale: 1px;
  outline: none;
  border: none;
  border-radius: 9px;
  background-color: var(--buttonactive);
  /* filter: drop-shadow(4px 4px 5px var(--buttonactive)); */
}

.eway_table_scroll {
  overflow: auto;
  /* padding: 20px 20px 20px 4rem; */
  background: var(--themeColor);
  width: calc(108% - 20px);
  scrollbar-width: thin;
  scrollbar-color: #adadad var(--themeColor);

  /* height: calc(100vh - 349px); */
}

.forward_head_label {
  font-size: var(--btnmediam);
  color: var(--black);
  border: 1px solid var(--black);
  font-weight: 400;
  text-align: center;
}
.forward_body_label {
  font-size: var(--btnmediam);
  color: var(--black);
  border: 1px solid var(--black);
  font-weight: 400;
  height: 80px;
  text-align: center;
}
.footer {
  background-color: var(--footerbgcolor);
}
.footerlabels {
  background: var(--footerbglabel);
}
.first_val {
  min-width: 80px !important;
  /* height: 20px; */
}
.four_val,
.eig_val,
.seveen_val {
  min-width: 100px !important;
}
.three_val,
.five_val,
.six_val {
  min-width: 70px !important;
}
.nine_val {
  min-width: 150px !important;
}
.sec_val {
  min-width: 120px !important;
}
.copy_doc_scroll {
  scrollbar-width: thin;
  /* scrollbar-color: #adadad var(--themeColor); */
  /* scroll-padding-inline-start: auto; */
  scroll-snap-align: start;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
.loading {
  width: 315px;
  height: 120px;
  position: relative;
  cursor: progress;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
  animation: loading 1.5s infinite;
}
@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
.loading_body {
  height: calc(100vh - 261px);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
