.main_container {
  height: 100vh;
  width: 100%;
  background-image: url(../src/common/assets/img/login\ background.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.inner_container {
  height: 100%;
}
.loginField_container {
  width: 350px;
  height: 65vh;
}
.omrs-input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}
.inputField_otp {
  height: 45px;
  width: calc(100% - 30px);
  padding: 10px;
  border-radius: 9px;
  outline: none;
  border: 1px solid blue;
}
.formA1_OfficeUse_headerTable {
  height: 20px;
  border: 2px solid black;
  border-top: 6px solid black;
  background-color: rgb(240, 219, 234);
}
.formA1_OfficeUse_BodyTable {
  height: 30px;
  border: 2px solid black;
  /* background-color: rgb(240, 219, 234); */
}
.formA1_DemandDraft_BodyHead {
  /* font-size: var(--xsmall); */
  /* font-weight: 400; */
  /* line-height: 21.74px; */
  width: 50%;
  border: 2px solid black;
  text-align: left;
  color: rgba(255, 255, 255, 0.555);
  margin: 0px;
}
.formA1-table-data {
  font-size: 16px;
  text-align: left;
  color: #000;
  margin: 0px;
  padding: 8px 8px;
}
.formA1-table-sectionA {
  font-size: 16px;
  text-align: left;
  color: rgba(255, 255, 255, 0.555);
  margin: 0px;
  height: 20px;
  width: auto;
  padding: 8px 8px;
}
.formA1-table,
.formA1-table td {
  border: 1px solid black;
}
.formA1-table-sectionB {
  font-size: 16px;
  text-align: left;
  color: rgba(255, 255, 255, 0.555);
  margin: 0px;
  padding: 4px 8px;
}
.formA1-table-sectionC {
  font-size: 14px;
  text-align: left;
  color: #000;
  margin: 0px;
  padding: 4px 8px;
}
.formA1-table,
.formA1-table td {
  border: 1px solid black;
}

@media only screen and (max-width: 1280px) {
  .formA1-table-data {
    font-size: 14px;
    padding: 6px 8px;
  }
  .formA1-table-sectionA {
    font-size: 14px;
    padding: 6px 8px;
  }
  .formA1-table-sectionB {
    font-size: 12px;
    padding: 4px 8px;
  }
  .formA1-table-sectionC {
    font-size: 12px;
    padding: 4px 8px;
  }
  .status-waiver-content {
    font-size: 16px;
  }
}

/* agent management css start */
.agent-view-user-table-head {
  padding: 10px 10px;
  background: #74512d !important;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: normal !important;
  vertical-align: middle !important;
  text-align: center;
  color: #ffffff !important;
  min-width: 70px;
}
.agent-view-user-table-data {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #000000 !important;
  padding: 10px 0px;
  min-width: 70px;
  vertical-align: middle !important;
  background-color: #f8f4e1 !important;
}
.agent-view-totalAmt {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  color: white !important;
  background-color: #74512d !important;
  /* text-align: center; */
  /* color: #000000; */
  /* padding: 10px 10px; */
}
/* agent management css end */

/* PACKING LIST */
.packing-list-sub-heading {
  font-family: Nunito;
  font-size: 22px;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  color: #74512d;
}
.--packinglist-input-block {
  width: 33%;
}
.packing-list-back-btn {
  width: 18%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: #af8f6f80;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
}
.packing-list-back-btn:hover {
  background: #543310;
}
.packing-list-next-btn {
  width: 18%;
  height: 42px;
  border-radius: 8px;
  background: #543310;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
}

.packingListInputContainer {
  /* display: flex;
  align-items: center; */
  overflow: auto !important;
  width: 100%;
  scrollbar-width: thin;

}
.packinglist-append-input-block {
  width: 33.33%;
  display: flex;
  align-items: center;
}
.packinglist-append-label {
  font-family: Nunito;
  font-size: var(--xx-small);
  font-weight: 800;
  line-height: normal;
  text-align: left;
  padding: 10px 5px;
  color: var(--buttonsec);
  min-width: 60px;
}
.packinglist-append-input {
  padding: 10px 5px;
  min-width: 90px;
  /* border: 1px solid red; */
}
.packinglist-view-btn {
  width: 100px;
  height: 25px;
  border-radius: 5px;
  background: #543310;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 800;
  line-height: 16.37px;
  text-align: center;
  color: #ffffff;
}
.packinglist-view-btn:hover {
  color: #543310;
  background-color: #ffffff;
}
.packinglist-download-button {
  height: 38px;
  border-radius: 5px;
  width: 20%;
  background: #ffffff;
  border: 1px solid #543310;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #543310;
}
.packinglist-download-button:hover {
  background-color: #543310;
  color: #ffffff;
}
.packing-list-input-block {
  width: 50%;
  display: flex;
  padding: 0px 0px 0px 15px;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
}
.packing-list-input {
  width: 60%;
}
.paking-list-label {
  font-family: Nunito;
  font-size: var(--xx-small);
  font-weight: 800;
  line-height: 19.1px;
  text-align: left;
  width: 35%;
  color: var(--buttonsec);
}
