.step-progress {
    margin: 20px;
  }
  
  .indexedStep {
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 1s ease, color 1s ease;
  }
  
  .indexedStep.previous {
    background-color:var(--line-color);
    color: var(--countColor); 
  }
  
  .indexedStep.current {
    background-color: #ffeb3b; /* Yellow for current step */
    color: var(--countColor); 
    height: 34px;
    width: 34px;
  }
  
  .indexedStep.next {
    background-color: var(--themeColor);
    color: var(--countColor);
    border: 1px solid #4caf50;
  }
  
  .indexedStep:not(.previous):not(.current):not(.next) {
    background-color: var(--themeColor);
    color: var(--countColor); 
    border: 1px solid #4caf50;
  }
  .RSPBprogressBar{
    background-color: transparent !important;
    border: 1px solid #4caf50;
  }

  