.dataEntryMainContainer {
  width: 100%;
  height: calc(100vh - 20px);
  overflow: hidden;
  border-radius: 15px;
  background: var(--themeColor);
}
.--copydocument {
  height: calc(100vh - 84px);
}
.data-entry-form-block {
  height: calc(100vh - 120px);
  overflow-y: scroll;
  padding-bottom: 50px;
}
/* /Scroll Start */

/* For WebKit browsers (Chrome, Safari) */
.data-entry-form-block {
  scrollbar-width: thin;
  scrollbar-color: #adadad var(--themeColor);
  /* scroll-padding-inline-start: auto; */
  scroll-snap-align: start;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
/* /Scroll End */
.data-entry-head {
  padding: 20px;
  border-radius: 15px 15px 0px 0px;
  background-color: var(--buttonsec);
}
.data-entry-title {
  font-family: Nunito;
  font-size: var(--medium);
  font-weight: 800;
  line-height: 32.74px;
  text-align: left;
  color: var(--white);
}
.dataEntryInputContainer {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* flex-wrap: wrap; */
  width: 100%;
}
.data-entry-input-block {
  width: 50%;
  display: flex;
  padding: 0px 0px 0px 15px;
  gap: 0.5rem;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}
.data-entry-input-block-two {
  width: 60%;
  display: flex;
  padding: 0px 0px 0px 15px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.data-entry-input-block-twos {
  width: 60%;
  display: flex;
  padding: 0px 0px 0px 15px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  /* flex-wrap: wrap; */
}
.data-entry-label {
  font-family: Nunito;
  font-size: var(--xx-small);
  font-weight: 800;
  line-height: 19.1px;
  text-align: left;
  width: 50%;
  color: var(--buttonsec);
}
.packing-list-label {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.1px;
  text-align: left;
  width: 100%;
  color: var(--buttonsec);
}
.--data-entry-label-two {
  width: 42%;
  color: var(--buttonsec);
}
.--data-entry-label-twos {
  width: 52%;
  color: var(--buttonsec);
}
.--append-label {
  font-family: Nunito;
  font-size: var(--xsmall);
  font-weight: 800;
  line-height: 19.1px;
  text-align: left;
  color: var(--buttonactive);
}
.data-entry-input {
  width: 80%;

  /* width: 100%; */
}
.data-entry-input-two {
  width: 58%;
}
.data-entry-input-field {
  height: 40px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid var(--buttonsec);
  font-family: Nunito;
  padding: 0px 0px 0px 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 16.37px;
  text-align: left;
  width: 100%;
}
.data-entry-sub-title {
  font-family: Nunito;
  font-size: var(--btnfont);
  font-weight: 800;
  line-height: 24.55px;
  text-align: center;
  color: var(--buttonsec);
}
.dataEntryAppendSection {
  /* padding: 18px 10px 18px 10px; */
  border-radius: 10px;
  background: var(--white);
  width: 100%;
  display: flex;
  gap: 5px;
  margin-top: 2rem;
}
.data-entry-append-block {
  width: 90%;
}
.dataEntryAppendInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  /* padding: 0px 20px; */
}
.dataEntryAppendInputImage {
  width: 100%;
  background: var(--buttonThree);
  height: 60px;
  padding: 0px 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dataEntryRemoveInputContainer {
  width: 10%;
  display: flex;
  justify-content: start;
  align-items: start;
}

.dataEntryAppendAddBtn {
  width: 100%;
  height: 60px;
  background: var(--buttonThree);
  padding: 0px 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.data-entry-append-input-field {
  height: 40px;
  border-radius: 5px;
  border: 1px;
  background: var(--themeColor);
  border: 1px solid var(--buttonsec);
  font-family: Nunito;
  padding: 0px 0px 0px 10px;
  font-size: var(--xx-small);
  font-weight: 400;
  line-height: 16.37px;
  text-align: left;
  width: 100%;
}
.dataEntryAppendInnerBlock {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px dashed var(--buttonactive);
  display: flex;
  align-items: center;
  justify-content: center;
}
.image_icon {
  width: 20px;
  height: 20px;
}
.data-entry-image-head {
  font-family: Nunito;
  font-size: var(--xsmall);
  font-weight: 800;
  line-height: 19.1px;
  text-align: left;
  color: var(--buttonactive);
}

.dataEntryAddCopyDocument {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  background: var(--buttonThree);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 5px;
  cursor: pointer;
}
.dataEntryAddInnerCopyDocument {
  height: 70px;
  border-radius: 10px;
  border: 1px dashed var(--buttonactive);
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-entry-submit-btn {
  width: 30%;
  height: 50px;
  border-radius: 10px;
  background: var(--buttonThree);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 800;
  line-height: 24.55px;
  color: var(--buttonsec);
  text-align: center;
  border: none;
}
.data-entry-submit-btn:hover {
  background-color: var(--buttonactive);
  color: var(--white);
}
.dataEntrySubmitBlock {
  width: 100%;
  text-align: center;
}

.imageIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.data-entry-date-field {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid var(--buttonsec);
  font-family: Nunito;
  padding: 0px 0px 0px 10px;
  font-size: var(--xx-small);
  font-weight: 400;
  line-height: 16.37px;

  text-align: left;
}
.react-datepicker-wrapper {
  width: 100%;
}

/* ********************** */
/* SUMMARY */

.summaryMainContainer_laas {
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  border-radius: 15px;
  /* border: 1px solid red; */
  scrollbar-width: thin;
  /* scrollbar-color: #adadad var(--themeColor); */
  /* scroll-padding-inline-start: auto; */
  scroll-snap-align: start;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
.summaryMainContainer {
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  border-radius: 15px;
  /* border: 1px solid red; */
}
.--hiddens {
  font-size: 14px;
  color: #000;
  border: none;
}
.billNoSearchField {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--buttonsec);
  font-family: Nunito;
  font-size: var(--xx-small);
  font-weight: 400;
  line-height: 16.37px;
  text-align: left;
  padding: 0px 10px;
  /* outline: none; */
}
.summary-head {
  /* width: 100%; */
  display: flex;
  align-items: center;
  width: 55%;
  justify-content: space-between;
  /* justify-content: space-between; */
}
.summary-label {
  font-family: Nunito;
  font-size: var(--xsmall);
  font-weight: 800;
  line-height: 19.1px;
  text-align: left;
  color: var(--buttonsec);
  width: 30%;
}
.summary-table-container {
  width: 100%;
  /* height: calc(100vh - 310px); */
  background: var(--themeColor);
  border-radius: 10px;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #adadad var(--themeColor);
  /* border: 1px solid red; */
}
.summary-table-container_single {
  width: 100%;
  /* height: calc(100vh - 310px); */
  background: var(--themeColor);
  border-radius: 10px;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #adadad var(--themeColor);
  /* border: 1px solid red; */
}

.summary-table {
  width: 100%;
  /* height: 250px !important; */
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
}
.summary-table_single {
  width: 100%;
  /* height: 250px !important; */
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
}
.summary-table-head {
  background: #74512d !important;
}
.summary_table_body {
  height: 50px !important;
}
.summary-table th {
  font-family: Inter;
  font-size: var(--xx-small);
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: var(--white);
  padding: 12px;
  min-width: 125px !important;
}
.summary-table td {
  text-align: center;
  font-family: Inter;
  font-size: var(--xx-small);
  font-weight: 600;
  line-height: 14.52px;
  color: #000000;
  /* height: 50px !important; */
  /* padding: px !important; */
  min-width: 125px !important;
}
.summary-table tbody tr {
  border-bottom: 1px solid #00000099;
}
/* ss */
.summary-table_single th {
  font-family: Inter;
  font-size: var(--xx-small);
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: var(--white);
  padding: 12px;
  min-width: 125px !important;
}
.summary-table_single td {
  text-align: center;
  font-family: Inter;
  font-size: var(--xx-small);
  font-weight: 600;
  line-height: 14.52px;
  color: #000000;
  padding: 16px !important;
  min-width: 125px !important;
}
.summary-table_single tbody tr {
  border-bottom: 1px solid #00000099;
}
.summary-download {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: end;
  justify-content: center;
  /* border: 1px solid red; */
}
.summary-download-btn {
  width: 25%;
  height: 40px;
  border-radius: 10px;
  background: var(--buttonfrst);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 800;
  line-height: 24.55px;
  text-align: center;
  color: #ffffff;
  border: none;
}
.summary-download-btn:hover {
  width: 25%;
  height: 40px;
  border-radius: 10px;
  background: var(--buttonactive);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 800;
  line-height: 24.55px;
  text-align: center;
  color: #ffffff;
  border: none;
}

/* ADD USER */
.addUserMainContainer {
  width: 100%;
  height: calc(100vh - 5vh);
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 15px;
  background: var(--themeColor);
}
.add-user-input-block {
  width: 100%;
}
.add-user-submit-btn {
  height: 45px;
  border-radius: 10px;
  background: var(--buttonfrst);
  border: none;
  font-family: Nunito;
  font-size: var(--btnfont);
  font-weight: 800;
  line-height: 24.55px;
  text-align: center;
  color: #74512d;
  width: 28%;
}
.add-user-submit-btn:hover {
  background-color: var(--buttonThree);
}
.addUserBtnContainer {
  width: 100%;
}
.--add-user-label {
  font-family: Nunito;
  font-size: var(--xsmall);
  font-weight: 800;
  line-height: 19.1px;
  text-align: left;
  width: 40%;
  color: var(--buttonsec);
}
.--add-user-input {
  width: 60%;
}
.add-user-form-block {
  width: 100%;
  height: inherit;
}
.viewUserBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* border: 1px solid red; */
}

/* PL */
.plMainContainer,
.statusWaiverMainContainer {
  width: 100%;
  height: calc(100vh - 70px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #adadad var(--themeColor);
  /* border-radius: 15px; */
  scrollbar-width: none;
  /* padding: 0px 20px; */
  /* margin: 0px 20px; */
}
.pl-head {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 35px;
}
.pl-search {
  width: 35%;
}
.--pl-input {
  border: none;
  outline: none;
}
.status-waiver-block {
  width: 100%;
  /* padding: 0px 20px; */
  margin: 10px 0px;
  background-color: #fff;
}
.status-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.76);
  background-color: #adadad;
  position: relative;
  /* margin-top: 15px; */
}

.status-waiver-content {
  font-size: var(--xsmall);
  font-weight: 400;
  line-height: 21.74px;
  text-align: left;
  color: #000000;
  margin: 0px;
}
.--bold-fonts {
  font-size: var(--btnfont);
}
.--para {
  font-size: var(--xx-small);
  font-weight: 400;
  line-height: 21.74px;
  text-align: left;
  color: #000;
  text-indent: 30px;
}
.--listPara {
  font-size: var(--xx-small);
  font-weight: 400;
  line-height: 21.74px;
  text-align: left;
  color: #000;
  font-style: italic;
}
.--content-border {
  border-bottom: 1px solid black;
}
.sblc-table-data {
  font-size: var(--xsmall);
  font-weight: 400;
  line-height: 21.74px;
  text-align: left;
  color: rgb(0, 0, 0);
  margin: 0px;
  padding: 10px 8px;
}
.sblc-table,
.sblc-table td {
  border: 1px solid black;
}
/* FILE UPLOAD */
.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.file-label:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 10px;
}
/* Login Page Start */
.forget_label {
  font-size: var(--xsmall);
  margin: 0px;
  padding: 0px 0px 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #282828;
  cursor: pointer;
  /* text-decoration: none; */
}
.sign-in-container {
  max-width: 800px;
  margin: auto;
  padding: 40px;
  height: 430px;
  width: 430px;
  padding: 41px;
  /* height: 400px; */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sign-in-container h2 {
  text-align: left;
  font-size: var(--medium);
  font-weight: 700;

  margin: 0px;
  padding: 0px;
  line-height: normal;
}
.reset_label {
  text-align: left;
  font-size: var(--btnmediam) !important;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  line-height: normal;
  margin-top: 5px;
}
.sign-in-container p {
  text-align: left;
  font-size: var(--small);
  font-weight: 400;
  margin: 10px 0px;
}

.input-container {
  position: relative;
  margin-bottom: 15px;
}
.error_message_input {
  position: relative;
  margin-bottom: 15px;
}

.icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  width: 10%;
  padding-right: 5px;
  border-right: 1px solid #000;
}
.iconpass {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  width: 9%;
  padding-right: 5px;
  cursor: pointer;
  /* border-right: 1px solid #000; */
}

.input-container input {
  width: 100%;
  padding: 10px 10px 10px 45px;
  box-sizing: border-box;
  border-radius: 9px;
  outline: none;
  margin: 10px 0px;
  border: 1px solid #543310;
}
.--reset-file input {
  padding: 10px 10px 10px 10px;
}

.sign-in-container form button {
  width: 100%;
  padding: 10px;
  background-color: var(--buttonThree);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sign-in-container form button:hover {
  background-color: var(--buttonactive);
}

/* Login Page End */
/* Popup container - hidden by default */
.user-add-button {
  width: 30%;
  padding: 12px 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 2px 2px 4px 0px #543310;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: #543310;
}
.user-add-button:hover {
  background-color: var(--buttonactive);
  color: var(--white);
}
.popup-content {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
  color: #000000;
}
.img-block {
  position: relative;
  top: -10px !important;
  left: -1%;
  width: 100% !important;
  height: 130px;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  background: url("./common/assets/img/rectangle_curve.png");
}
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
/* Popup content */
.popup-content {
  position: relative;
  /* padding: 20px; */
  width: 80%;
  max-width: 570px;
  background-color: #f8f4e1;
}
.user-delete-button {
  width: Hug (156px) px;
  padding: 15px 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 2px 2px 4px 0px #543310;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: #543310;
  border: none;
}
.resend_label_otp {
  font-size: var(--xx-small) !important;
  text-align: center !important;
}
.reset_btn {
  width: 30% !important;
  padding: 2px !important;
  font-size: var(--xx-small) !important;
  background-color: var(--buttonThree);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
}
.login_overall_btn {
  width: 100%;
  padding: 10px;
  background-color: var(--buttonThree);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* margin: 10px 0px;
  display: flex;
    justify-content: center; */
}
.login_overall_btn:hover {
  background-color: var(--buttonactive);
}
.table-search {
  width: 100%;
  border: none;
  outline: none;
}
.new-user-btn {
  width: 100%;
  padding: 7px 10px;
  /* background: #ffffff; */
  background: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: #543310;
  border: none;
}
.search-block {
  border: 1.5px solid #74512d;
  background: #ffffff;
  border-radius: 5px !important;
  width: 22%;
  height: 31px;
  padding: 0px 4px;
}
.view-table-block {
  width: 100%;
  background-color: #f8f4e1;
  /* height: 535px; */
  height: calc(100vh - 18vh);
  border-radius: 10px;
  overflow: auto !important;
  scrollbar-width: thin !important;
}
.view-table-block-forward {
  width: 100%;
  background-color: #f8f4e1;
  height: 470px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  scrollbar-width: none;
}
.view-user-table-head {
  padding: 8px !important;
  background: #74512d;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: #ffffff;
  min-width: 110px !important;
}
.view-user-table {
  width: 100%;
}
.table-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #f8f4e1;
}
.view-user-table-data {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  color: #000000;
  padding: 8px !important;
  min-width: 110px !important;
}
.view-user-table-row {
  border-bottom: 1px solid black;
  cursor: pointer;
}
.--first-head {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.--last-head {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.img-icon {
  width: 100%;
  height: 100%;
}
.add-user-btn-block {
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 2px 2px 4px 0px #543310;
  display: flex;
  width: 100%;
  padding: 3px 5px;
  cursor: pointer;
}
.arrow-icons {
  cursor: pointer;
}
.arrow-btn {
  border: none;
  outline: none;
  background-color: #f8f4e1;
  font-size: 20px;
  /* height: 25px; */
  width: 34px;
  text-align: center;
  border-radius: 5px;
}
.arrow-btn:hover {
  background-color: #74512d;
  color: #fff;
}
.viewUserMainContainer {
  width: 100%;
  background-color: white;
  height: 100vh;
  overflow-y: auto;
}

.file-remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
}
.upload_block {
  display: flex;
  /* width: 30%; */
  align-items: center;
  padding: 8px !important;
  /* background: #f0f0f0; */
  border-radius: 5px;
  padding: 5px 10px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
.--select-option {
  appearance: none;
}
.addUserPassword {
  border: none;
  outline: none;
  font-size: var(--xx-small);
  font-weight: 400;
  line-height: 16.37px;
  text-align: left;
  font-family: Nunito;
  width: 100%;
}
.addUserInputBlock {
  height: 40px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid var(--buttonsec);
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
.--password-icon {
  cursor: pointer;
}
.model_error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 400; */
  background: #fff;
  border: 2px solid red;
  box-shadow: 24;
  padding: 10;
  width: 500px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
}
.model_error h3 {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}
.error_message_input input {
  width: 100%;
  padding: 10px 10px 10px 45px;
  box-sizing: border-box;
  border-radius: 9px;
  outline: none;
  margin: 10px 0px 0px;
  /* border: 1px solid #543310; */
  border: 1px solid red;
}
.error_mess {
  /* margin: 0px !important;
  padding: 0px !important; */
  font-size: 12px !important;
  font-weight: 300;
  color: red;
}
.copy_docconfi {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
}
.copy_docconfi::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* loading.. */
.wrapper {
  width: 200px;
  height: 60px;
  position: absolute;
  /* left:0%;
    top:0%; */
  transform: translate(50%, 50%);
  overflow: hidden;
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #adadad7c;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  position: absolute;
  top: 75px;
  font-family: "Lato";
  font-size: 20px;
  letter-spacing: 12px;
  color: #000;
  left: 15%;
}
.load-content {
  color: brown;
  font-weight: 700;
  font-size: 18px;
}
.--borderrabiussright {
  border-radius: 0px 10px 10px 0px;
}
.--borderrabiussleft {
  border-radius: 10px 0px 0px 10px;
}
.--BG_VVV {
  border: 1px solid #000;
  background: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4));
}
.--new-search {
  border: 1.5px solid #74512d;
  background: #ffffff;
  border-radius: 5px !important;
  width: 100%;
  height: 35px;
  padding: 0px 4px;
  outline: none;
}
.search-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1.5px solid #74512d;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #d1cfcf28;
}
